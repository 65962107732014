.with-checkbox{
    [class*=MuiFormControl].MuiTextField-root {
        .MuiInputBase-root{
            padding: 5px;
            height:auto !important;
        }
        .MuiFormLabel-root{
            transform: translate(14px, 12px) scale(1) !important;  

            &.MuiInputLabel-shrink {
                transform: translate(30px, -8px) scale(0.75) !important;
            }
        }
    }
}


[class^="MuiAutocomplete"]{

    .MuiOutlinedInput-root {
        padding: 0;
    }

    font-family: var(--font-family);
    font-size: var(--font-size-s);

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: var(--color-neutral-4);
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--color-neutral-6);
    }

    li{     
        &:last-child{
            margin-bottom: 0;
        }
    }

    span.MuiCheckbox-root{
        height: 24px;
        width: 24px;
        margin-right: 8px;
    }

    [class*=MuiFormControl].MuiTextField-root {
        .MuiInputBase-root{
            height:40px;
        }

        .MuiFormLabel-root{
            line-height: 1.9;
            font-size: var(--font-size-s);
            transform: translate(14px, 9px) scale(1);  
            
            &.MuiInputLabel-shrink {
                transform: translate(29px, -8px) scale(0.75);
            }
        }        
}

    [class*="MuiFormControl"]{

        button[class*="MuiButton"]{

            color: var(--color-neutral-7);
            border: none;
            width: 28px;
            height: 28px;
            border-radius: 0;
            background-color: transparent;

            .MuiTouchRipple-ripple{
                color:transparent;
            }

            &[class*="MuiButtonBase"]{

                &:hover{
                    background-color: transparent;
                    box-shadow: none;
                }  
            }

            &[class*="Mui-disabled"]{
                background-color: inherit;
            }
        }
    }

    [class^=MuiChip]{
        font-size: var(--font-size-xs);
        display: inline;
        height: auto;
    }

    .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]{
            background-color:var(--color-bg-selected);
    }

    .MuiAutocomplete-option{
        padding: var(--space-s) var(--space-base);
        width: 100%;
    }
}