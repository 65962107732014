.popover {

    .MuiPaper-root {
        font-family: var(--font-family);
        border-radius: var(--border-radius-soft);
        padding: var(--space-m);
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
    }

    &.padding-none {

        .MuiPaper-root {
            padding: 0;
        }
    }

}