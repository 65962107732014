[class^="MuiSwitch"]{

    padding: 0;
    height: 32px;

    [class*="MuiSwitch-switchBase"]{
        transform:translateX(1px) translateY(-1px);
        
        padding: 4px;
    }

    [class*="MuiSwitch-thumb"]{

        width:24px;
        height: 24px;
        box-shadow: none;
        background-color: var(--color-neutral-5);
    }

    .Mui-checked {


        &[class*="colorPrimary"]{
            color: var(--color-neutral-0);
            
        }

        &+.MuiSwitch-track{
            background-color: var(--color-primary);
            opacity: 1;
            border-color: var(--color-primary);
     

        }

        [class*="MuiSwitch-thumb"]{
            background-color: var(--color-neutral-0);

        }

        &[class*="MuiSwitch-switchBase"]{
            transform: translateX(25px) translateY(-1px);
        }

    }

    .MuiSwitch-track{
        background-color: var(--color-neutral-0);
        opacity: 1;
        border-radius: 45px;
        border: var(--border-size-s) solid var(--color-border);
        height: 30px;

    }

    .Mui-disabled {

        [class*="MuiSwitch-thumb"]{
            background-color: var(--color-neutral-5);
        }
           
        &+.MuiSwitch-track{
            background-color: var(--color-bg-disabled);
            opacity: 1;
            border-color: var(--color-border);
        }
    }

}
