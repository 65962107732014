[class^="MuiChip"] {
  height: 32px;
  font-size: var(--font-size-s);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  word-break: normal;
  border-radius: var(--border-radius-rounded);

  .MuiChip-label {
    color: var(--color-neutral-0);
    padding: 0 var(--space-base);
  }

  $colors: ("success", "error", "info", "warning", "primary", "secondary");

  @each $color in $colors {
    &.background-#{$color} {
      background-color: var(--color-#{$color});

      &-light {
        background-color: var(--color-#{$color}-light);

        .MuiChip-label {
          color: var(--color-#{$color});
        }
      }
    }
  }

  &.background-primary-light,
  &.background-secondary-light {
    border: var(--border-size-s) solid var(--color-border);
  }

  $neutralColors: (
    0 "0",
    1 "1",
    2 "2",
    3 "3",
    4 "4",
    5 "5",
    6 "6",
    7 "7",
    8 "8",
    9 "9",
    10 "10"
  );

  @each $value, $color in $neutralColors {
    &.background-neutral-#{$color} {
      background-color: var(--color-neutral-#{$color});

      .MuiChip-label {
        @if ($value < 7) {
          color: var(--color-neutral-9);
        } @else {
          color: var(--color-neutral-0);
        }
      }

      &-light {
        @if ($value < 7) {
          background-color: var(--color-neutral-10);

          .MuiChip-label {
            color: var(--color-neutral-0);
          }
        } @else {
          background-color: var(--color-neutral-0);

          .MuiChip-label {
            color: var(--color-neutral-#{$color});
          }
        }
      }
    }
  }

  &.large {
    height: 40px;
    font-size: var(--font-size-base);
  }

  &.small {
    height: 24px;

    .MuiChip-label {
      padding: 0 var(--space-s);
      font-size: var(--font-size-xs);
    }
  }
}

.MuiButtonBase-root.MuiChip-root {
  &.clickable {
    &:active {
      background-color: var(--color-neutral-6) !important;
      box-shadow: none;
    }

    &:hover {
      background-color: var(--color-neutral-5);
    }
  }

  &.deletable {
    background-color: var(--color-neutral-3);

    .MuiChip-label {
      color: var(--color-neutral-7);
    }

    svg {
      fill: var(--color-neutral-7);
      margin-right: var(--space-s);
      font-size: var(--font-size-base);

      &:hover {
        fill: var(--color-neutral-8);
      }
    }
  }
}
