.MuiPagination-root {
    ul.MuiPagination-ul {
        border: none; 

        li {
            button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page,
            button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext {
                border-radius: var(--border-radius-soft);
                color: var(--color-neutral-7);
                padding: 0;
                width: 32px;
                height: 32px;
                margin: 0 4px;
            
                &.Mui-selected {
                    background-color: var(--color-primary);
                    color: var(--color-neutral-0);
                    border: var(--border-size-s) solid var(--color-primary);
                }
            }

            button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext.Mui-disabled[disabled] {
                border-radius: var(--border-radius-soft);
                width: 32px;
                height: 32px;
                color: var(--color-neutral-4);
                border-color: var(--color-neutral-4);
                background-color: var(--color-neutral-0);
                opacity: 1;
            }
        }
    }
}
