  
.accordion-item:last-child{
    border-bottom: 1px solid var(--color-neutral-5);
}

[class*=MuiPaper-root-MuiAccordion-root]:first-of-type{
        border-top: 1px solid var(--color-neutral-5);
}

.MuiAccordion-root{
    border-top: none;
    border-left: 1px solid var(--color-neutral-5);
    border-right: 1px solid var(--color-neutral-5);
    box-shadow: none;

    .MuiAccordionSummary-content {
        font-size: var(--font-size-h6);
    }

    .MuiAccordionDetails-root {
        font-size: var(--font-size-s);
    }

    &:active{
        background-color:var(--color-bg-pressed);
    }

    &.Mui-expanded{
        margin: 0;
        border-top: 2px solid var(--color-neutral-7);
    }

    .MuiAccordionSummary-expandIconWrapper{
        color: var(--color-primary);
    } 

    [class*=MuiButtonBase-root-MuiAccordionSummary-root].Mui-disabled{
        opacity: 1;
    }

    &.Mui-disabled{
        background-color: var(--color-bg-disabled);
        color:  var(--color-tx-disabled);
 
        .MuiAccordionSummary-expandIconWrapper{
            color: var(--color-tx-disabled);
        } 
    }
 
    // &:before {
    //     border-top: 1px solid var(--color-neutral-5);
    // }

    // & + .MuiAccordion-root:before {
    //     border-top: 1px solid transparent;
    // }
}