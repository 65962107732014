.center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontal-align{
    display: flex;
    align-items: center;
}

.MuiDrawer-root{
 
}