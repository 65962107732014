[class^="MuiAvatar"] {
  width: 32px;
  height: 32px;
  font-size: var(--font-size-s);
  line-height: 24px;
  background-color: var(--color-primary);
  color: var(--color-neutral-0);

  &.background-primary {
    background-color: var(--color-primary);
    color: var(--color-neutral-0);

    &-light {
      border: var(--border-size-s) solid var(--color-border);
      background-color: var(--color-neutral-0);
      color: var(--color-primary);
    }
  }

  &.background-secondary {
    background-color: var(--color-secondary);
    color: var(--color-neutral-0);

    &-light {
      border: var(--border-size-s) solid var(--color-border);
      background-color: var(--color-neutral-0);
      color: var(--color-secondary);
    }
  }

  $neutralColors: (
    0 "0",
    1 "1",
    2 "2",
    3 "3",
    4 "4",
    5 "5",
    6 "6",
    7 "7",
    8 "8",
    9 "9",
    10 "10"
  );

  @each $value, $color in $neutralColors {
    &.background-neutral-#{$color} {
      background-color: var(--color-neutral-#{$color});

      @if ($value < 7) {
        color: var(--color-neutral-9);
      } @else {
        color: var(--color-neutral-0);
      }

      &-light {
        @if ($value < 7) {
          background-color: var(--color-neutral-10);
          color: var(--color-neutral-0);
        } @else {
          background-color: var(--color-neutral-0);
          color: var(--color-neutral-#{$color});
        }
      }
    }
  }

  &.large {
    width: 40px;
    height: 40px;
    font-size: var(--font-size-base);
  }

  &.small {
    width: 24px;
    height: 24px;
  }
}
