/* Variables */

//Devices
$phone-width: 475px;
$tablet-width: 1024px;

:root {

    /*! Color - Neutral */
    --color-neutral-0: #ffffff;
    --color-neutral-1: #f8f9fa;
    --color-neutral-2: #f1f3f5;
    --color-neutral-3: #e9ecef;
    --color-neutral-4: #dee2e6;
    --color-neutral-5: #CED4DA;
    --color-neutral-6: #9EA6AE;
    --color-neutral-7: #6a7178;
    --color-neutral-8: #4f575e;
    --color-neutral-9: #272b30;
    --color-neutral-10: #000000;

    //*! Color - Extended */
    --color-red-lightest: #faeaea;
    --color-red-lighter: #e9aaaa;
    --color-red-light: #d96a6a;
    --color-red: #c92a2a;
    --color-red-dark: #ab2424;
    --color-red-darker: #8c1d1d;
    --color-red-darkest: #6f1717;

    --color-orange-lightest: #fef0e6;
    --color-orange-lighter: #fcc29c;
    --color-orange-light: #f99551;
    --color-orange: #f76707;
    --color-orange-dark: #d25705;
    --color-orange-darker: #ad4805;
    --color-orange-darkest: #883904;

    --color-yellow-lightest: #fef5e5;
    --color-yellow-lighter: #fbd999;
    --color-yellow-light: #f8bc4c;
    --color-yellow: #f59f00;
    --color-yellow-dark: #d08700;
    --color-yellow-darker: #ac6f00;
    --color-yellow-darkest: #875700;

    --color-lime-lightest: #f1f8e8;
    --color-lime-lighter: #c7e3a2;
    --color-lime-light: #9ecd5c;
    --color-lime: #74b816;
    --color-lime-dark: #639c13;
    --color-lime-darker: #51810f;
    --color-lime-darkest: #40650c;

    --color-green-lightest: #ebf7ed;
    --color-green-lighter: #afe0b8;
    --color-green-light: #73c982;
    --color-green: #37b24d;
    --color-green-dark: #2f9741;
    --color-green-darker: #267d36;
    --color-green-darkest: #1e612a;

    --color-teal-lightest: #e6f2ef;
    --color-teal-lighter: #9cccbd;
    --color-teal-light: #52a58c;
    --color-teal: #087f5b;
    --color-teal-dark: #076c4d;
    --color-teal-darker: #065940;
    --color-teal-darkest: #044632;

    --color-cyan-lightest: #e7f2f4;
    --color-cyan-lighter: #9eccd3;
    --color-cyan-light: #56a6b2;
    --color-cyan: #0d8091;
    --color-cyan-dark: #0b6d7b;
    --color-cyan-darker: #095a66;
    --color-cyan-darkest: #074650;

    --color-blue-lightest: #e5f0fe;
    --color-blue-lighter: #a3c9ea;
    --color-blue-light: #5fa1db;
    --color-blue: #1a79cb;
    --color-blue-dark: #1667ad;
    --color-blue-darker: #12558e;
    --color-blue-darkest: #0e4370;

    --color-indigo-lightest: #eceffd;
    --color-indigo-lighter: #b3c1f7;
    --color-indigo-light: #7b92f1;
    --color-indigo: #4263eb;
    --color-indigo-dark: #3854c8;
    --color-indigo-darker: #2e45a5;
    --color-indigo-darkest: #243681;

    --color-violet-lightest: #f1edfd;
    --color-violet-lighter: #c6b6f6;
    --color-violet-light: #9b7fef;
    --color-violet: #7048e8;
    --color-violet-dark: #5f3dc5;
    --color-violet-darker: #4e32a2;
    --color-violet-darkest: #3e2880;

    --color-grape-lightest: #f7ecfa;
    --color-grape-lighter: #dfb2e9;
    --color-grape-light: #c678d9;
    --color-grape: #ae3ec9;
    --color-grape-dark: #9435ab;
    --color-grape-darker: #7a2b8d;
    --color-grape-darkest: #60226f;

    --color-pink-lightest: #fbebf0;
    --color-pink-lighter: #efadc4;
    --color-pink-light: #e27098;
    --color-pink: #d6336c;
    --color-pink-dark: #b62b5b;
    --color-pink-darker: #96244c;
    --color-pink-darkest: #761c3b;

    /*! iOS Safe Areas (Notch Support) */
    --os-safe-area-top: env(safe-area-inset-top);
    --os-safe-area-right: env(safe-area-inset-right);
    --os-safe-area-bottom: env(safe-area-inset-bottom);
    --os-safe-area-left: env(safe-area-inset-left);

    /*! Typography - Size */
    --font-size-h1: 32px;
    --font-size-h2: 28px;
    --font-size-h3: 26px;
    --font-size-h4: 22px;
    --font-size-h5: 20px;
    --font-size-h6: 18px;
    --font-size-display: 36px;
    --font-size-base: 16px;
    --font-size-s: 14px;
    --font-size-xs: 12px;
    --font-size-xxs: 10px;

    /*! Typography - Weight */
    --font-light: 300;
    --font-regular: 400;
    --font-semi-bold: 600;
    --font-bold: 700;

    /*! Color - Brand */
    --color-primary: #ED002F;
    --color-secondary: #000000;
    --color-tertiary: #ED002F;
    --color-primary-lightest: #ED002F;
    --color-primary-hover: #D7022C;
    --color-primary-selected: rgba(20, 110, 245, 0.12);
    --color-primary-lightest: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
    --color-link:#ED002F;
    --color-link-hover: #d7022c;    

    /*! Color - States */
    --color-focus-outer: #ffd337;
    --color-focus-inner: var(--color-neutral-10);
    --color-border: var(--color-neutral-5);
    --color-border-hover: var(--color-neutral-6);
    --color-bg-hover: #E8E8E8;
    --color-bg-pressed: #CBCBCB;
    --color-bg-selected: #ffe6eb;
    --color-border-focus: #4fa4ee;
    --color-bg-disabled: #f3f3f3;
    --color-border-error: #dc2020;
    --color-tx-disabled: #9ea6ae;
    --color-link-focus: #4fa4ee;

    /*! Color - Semantic */
    --color-error-light: #fceaea;
    --color-error: #dc2020;
    --color-warning-light: #fdf6e5;
    --color-warning: #e9a100;
    --color-success-light: #eaf3eb;
    --color-success: #29823b;
    --color-info-light: #e5f5fc;
    --color-info: #017aad;

    /*! Overlays */
    --overlay-background: rgba(0, 0, 0, 0.4);

    /*! Space - Sizes */
    --space-none: 0;
    --space-xs: 4px;
    --space-s: 8px;
    --space-base: 16px;
    --space-m: 24px;
    --space-l: 32px;
    --space-xl: 40px;
    --space-xxl: 48px;

    /*! Border Radius */
    --border-radius-none: 0;
    --border-radius-soft: 4px;
    --border-radius-rounded: 20px;
    --border-radius-circle: 100%;

    /*! Border Size */
    --border-size-none: 0;
    --border-size-s: 1px;
    --border-size-m: 2px;
    --border-size-l: 4px;

    /*! Elevation / Shadow */
    --shadow-none: none;
    --shadow-xs: 0px 1px 2px rgba(0, 0, 0, 0.1);
    --shadow-s: 0px 2px 4px rgba(0, 0, 0, 0.1);
    --shadow-m: 0px 1px 12px rgba(22, 34, 51, 0.05);
    --shadow-l: 0px 1px 12px rgba(22, 34, 51, 0.15);
    --shadow-xl: 0px 8px 10px rgba(0, 0, 0, 0.1);

    /*! App Settings */
    --header-color: var(--color-neutral-0);
    --color-background-body: var(--color-neutral-1);
    --color-background-login: #F8F9F;
    --header-size: 70px;
    --header-size-content: 48px;
    --side-menu-size: 300px;
    --bottom-bar-size: 56px;
    --footer-height: 56px;
    --footer-color: #ffffff;
    --btn-and-input-height : 40px;
    --font-family: 'SofiaPro-Regular';
 
    /*! Responsive Settings */

    --phone: $phone-width;
    --tablet: $tablet-width;
}

