
[class*=MuiLinearProgress] {
    background-color: var(--color-neutral-3);
    border-radius: var(--border-radius-rounded);
    height: 12px;

    .MuiLinearProgress-bar {
        background-color: var(--color-primary);
    } 
   
    $colors: (
        "success",
        "error",
        "info",
        "warning",
        "primary",
        "secondary",
        "neutral-10",
        "neutral-9",
        "neutral-8",
        "neutral-7",
        "neutral-6",
        "neutral-5",
        "neutral-4",
        "neutral-3",
        "neutral-2",
        "neutral-1",
        "neutral-0",
    );

    @each $color in $colors {
        &.background-#{$color} {
         
            .MuiLinearProgress-bar {
                background-color: var(--color-#{$color});
            } 
        }    
    }    
}

.MuiCircularProgress-root {
    color: var(--color-primary);
       
    $colors: (
        "success",
        "error",
        "info",
        "warning",
        "primary",
        "secondary"
        "neutral-10",
        "neutral-9",
        "neutral-8",
        "neutral-7",
        "neutral-6",
        "neutral-5",
        "neutral-4",
        "neutral-3",
        "neutral-2",
        "neutral-1",
        "neutral-0",
    );

    @each $color in $colors {
        &.background-#{$color} {
            background-color: transparent;
         
            svg {
                color: var(--color-#{$color});
            } 
        }    
    }    
}

