/*! Usefull - Images */

img {

    &-cover {
        height: 100%;
        object-fit: cover;
    }

    &.img-rounded {
        border-radius: 10px;
    }

    &.img-circle {
        border-radius: var(--border-radius-circle);
    }

    &.thumbnail {
        background-color: var(--color-neutral-0);
        border: var(--border-size-s) solid var(--color-neutral-4);
        padding: var(--space-xs);
    }

}

.full-width {
    width: 100% !important;
}

.full-width-vw {
    width: 100vw !important;
}

.half-width { 
    width: 50% !important; 
}

.half-width-vw {
    width: 50vw !important;
}

.full-height {
    height: 100% !important;
}

.full-height-vh {
   height: 100vh !important; 
}

.half-height {
    height: 50% !important; 
}

.half-height-vh {
    height: 50vh !important; 
}

.auto-height {
    height: auto !important;
}

.full-height-minus-header {
    height: calc(100vh - var(--header-size));
}

 

.tablet .tablet-full-width,
.phone .phone-full-width {
    margin-left: 0;
    width: 100%;
}

.line-height-reset {
    line-height: 1;
}


/*! Usefull - Overflow */

.overflow {
    &-hidden {
        overflow: hidden;
    }
    
    &-horizontal {
        overflow-x: auto;
    }
    
    &-vertical {
        overflow-y: auto;
    }

    &-auto {
        overflow: auto;
    }
}


/*! Usefull - Visibility */

.ph:empty,
.hidden {
    display: none;
}

.visibility-hidden {
    visibility: hidden;
}

.hide-scrollbar {
    scrollbar-width: none;

    &:hover {
        cursor: all-scroll;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}
