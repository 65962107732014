/*! Usefull - Margin */

$margin-values: (
    "none",
    "xs",
    "s",
    "base",
    "m",
    "l",
    "xl",
    "xxl"
);

$margin-position: (
    "top",
    "right",
    "bottom",
    "left",
    "x",
    "y"
);

// Margin
@each $value in $margin-values {
    .margin-#{$value} {
        margin: var(--space-#{$value}) !important;
    }

    @each $position in $margin-position {
        .margin-#{$position}-#{$value} {
            @if ($position=="x") {
                margin-left: var(--space-#{$value}) !important;
                margin-right: var(--space-#{$value}) !important;
            }

            @else if ($position=="y") {
                margin-bottom: var(--space-#{$value}) !important;
                margin-top: var(--space-#{$value}) !important;
            }

            @else {
                margin-#{$position}: var(--space-#{$value}) !important;
            }
        }
    }
}

.margin-auto {
    margin: 0 auto;
}

// is RTL
@each $value in $margin-values {
    @each $position in $margin-position {

        @if ($position == "left") {
            .is-rtl .margin-#{$position}-#{$value} {
                margin-left: var(--space-none) !important;
                margin-right: var(--space-#{$value}) !important;
            }
        }

        @else if ($position == "right") {
            .is-rtl .margin-#{$position}-#{$value} {
                margin-left: var(--space-#{$value}) !important;
                margin-right: var(--space-none) !important;
            }
        }
    }
}
