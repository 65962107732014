/*! Usefull - Position */

.position-relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.static {
    position: static;
}


/*! Usefull - Position Absolute */

.position-absolute {
    position: absolute;
}

.absolute {
    &-top {
        top: 0;
    }

    &-top-right {
        right: 0;
        top: 0;
    }

    &-top-left {
        left: 0;
        top: 0;
    }

    &-top-plus-header {
        top: var(--header-size);
    }

    &-right {
        right: 0;
    }

    &-bottom {
        bottom: 0;
    }

    &-bottom-right {
        bottom: 0;
        right: 0;
    }

    &-bottom-left {
        bottom: 0;
        left: 0;
    }

    &-bottom.absolute-center {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &-left {
        left: 0;
    }

    &-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &-center-top {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    &-center-right {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &-center-bottom {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &-center-left {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
