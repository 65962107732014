[class*=MuiBackdrop]{
    &.MuiBackdrop-visible{
    background-color: var(--overlay-background);
    }

    &:not(.MuiBackdrop-visible){
        background-color: transparent;

    }
        // background-color: rgba(0, 0, 0, 0.4);
        

        [class*=MuiCircularProgress-circle]{

            // color: var(--color-neutral-0);
            color: var(--color-primary);
        }
        
}

