/*! Usefull - Border Radius */

$border-radius-values: (
    none,
    soft,
    rounded,
    circle
);

$border-radius-direction: (
    top-left,
    top-right,
    bottom-right,
    bottom-left,
    top ("top-left", "top-right"),
    bottom ("bottom-left", "bottom-right")
);

@each $value in $border-radius-values {
    .border-radius-#{$value} {
        border-radius: var(--border-radius-#{$value});
    }

    @each $direction, $properties in $border-radius-direction {
        .border-radius-#{$direction}-#{$value} {
            @if $properties {
                @each $property in $properties {
                    border-#{$property}-radius: var(--border-radius-#{$value});
                }
            } @else {
                border-#{$direction}-radius: var(--border-radius-#{$value});
            }
        }
    }
}


.border-radius-top-right-none{
    border-top-right-radius: var(--border-radius-none);
}

.border-radius-bottom-right-none{
    border-bottom-right-radius: var(--border-radius-none);
}

.border-radius-top-left-none{
    border-top-left-radius: var(--border-radius-none);
}

.border-radius-bottom-left-none{
    border-bottom-left-radius: var(--border-radius-none);
}

.border-radius-top-none{
    border-top-left-radius: var(--border-radius-none);
    border-top-right-radius: var(--border-radius-none);
}

.border-radius-right-none{
    border-top-right-radius: var(--border-radius-none);
    border-bottom-right-radius: var(--border-radius-none);
}

.border-radius-bottom-none{
    border-bottom-right-radius: var(--border-radius-none);
    border-bottom-left-radius: var(--border-radius-none);
}

.border-radius-left-none{
    border-top-left-radius: var(--border-radius-none);
    border-bottom-left-radius: var(--border-radius-none);
}

.border-radius-top-right-soft{
    border-top-right-radius: var(--border-radius-soft);
}

.border-radius-bottom-right-soft{
    border-bottom-right-radius: var(--border-radius-soft);
}

.border-radius-top-left-soft{
    border-top-left-radius: var(--border-radius-soft);
}

.border-radius-bottom-left-soft{
    border-bottom-left-radius: var(--border-radius-soft);
}

.border-radius-top-soft{
    border-top-left-radius: var(--border-radius-soft);
    border-top-right-radius: var(--border-radius-soft);
}

.border-radius-right-soft{
    border-top-right-radius: var(--border-radius-soft);
    border-bottom-right-radius: var(--border-radius-soft);
}

.border-radius-bottom-soft{
    border-bottom-left-radius: var(--border-radius-soft);
    border-bottom-right-radius: var(--border-radius-soft);
}

.border-radius-left-soft{
    border-top-left-radius: var(--border-radius-soft);
    border-bottom-left-radius: var(--border-radius-soft);
}

.border-radius-top-rounded{
    border-top-left-radius: var(--border-radius-rounded);
    border-top-right-radius: var(--border-radius-rounded);
}

.border-radius-right-rounded{
    border-top-right-radius: var(--border-radius-rounded);
    border-bottom-right-radius: var(--border-radius-rounded);
}

.border-radius-bottom-rounded{
    border-bottom-left-radius: var(--border-radius-rounded);
    border-bottom-right-radius: var(--border-radius-rounded);
}

.border-radius-left-rounded{
    border-top-left-radius: var(--border-radius-rounded);
    border-bottom-left-radius: var(--border-radius-rounded);
}


/*! Usefull - Border Size */

$border-size-values: (
    none,
    s,
    m,
    l
);

$border-size-positions: (
    top,
    right,
    bottom,
    left
);

@each $value in $border-size-values {

    .border-size-#{$value} {
        @if ($value == none) {
            border-width: var(--border-size-none);
        } @else {
            border: var(--border-size-#{$value}) solid currentColor;
        }
    }
}

@each $position in $border-size-positions {

    @each $value in $border-size-values {

        @if ($value != none) {
            .border-#{$position}-#{$value} {
            
                &:not(.columns),
                &.columns>.columns-item:not(:last-child) {
                    border-#{$position}: var(--border-size-#{$value}) solid currentColor;
                }
            }
        }
        
    }
    
}
