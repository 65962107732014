.horizontal-align{
    display: flex;
    align-items: center;
}

.MuiCard-root{
        border-radius: 8px;
        box-shadow: var(--shadow-m);
        display: flex; 
        padding: var(--space-base); 
        
        .MuiCardMedia-root {
            width: 40%;
        }

    .MuiCardContent-root{
        width:100%;
        padding: 0;
    }
}