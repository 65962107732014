
[class^="MuiFormGroup"]{

    [class*="MuiButtonBase"]{

        color: var(--color-neutral-5);
  
    }

    [class*="MuiSvgIcon"]{
       
        font-size: 30px;
        
    }

    .Mui-checked {

       
        &[class*="colorPrimary"]{

            color: var(--color-primary);
        }

        &.Mui-disabled{          

            color: var(--color-neutral-6);


            }
    }

    [class*="Mui-disabled"]{

        color:var(--color-neutral-6);
    } 
   
}