span.MuiCheckbox-root{
    height: 48px;
    width: 48px;
    padding: 0;

    svg {
        fill: var(--color-border);

    }

    &.Mui-checked {

        svg {
            fill: var(--color-primary);
        }
    }

    &.Mui-disabled:not(.Mui-checked) {

        &:before {
            content: "";
            position: absolute;
            width: 21px;
            height: 21px;
            background-color: var(--color-bg-disabled);
            z-index: -1;
        }
    }

    &.Mui-checked.Mui-disabled {

        background-color: transparent;

        svg {
            fill: var(--color-border);
        }
    }

    &.error {

        svg {
            fill: var(--color-error);
        }
    }

    svg {
        font-size: var(--font-size-h1);
    }
}

.MuiFormControlLabel-root {

    .MuiFormControlLabel-label {
        margin-left: var(--space-s);
        font-size: var(--font-size-s); 
            
        &.Mui-disabled {
            color: var(--color-secondary);
        }
    }

    .MuiFormControlLabel-asterisk {
        color: var(--color-primary);
    }
}


//Outras variações de estilização usando :after e :before ou explorando o stroke dos svg
//Explorando os SVG's

/*span.MuiCheckbox-root{
    padding: 0;
    stroke: var(--color-neutral-0);
    stroke-width: 1; 
    
    svg {
        fill: var(--color-border);
    }

&.Mui-checked {
    stroke: var(--color-primary);
    stroke-width: 1;
    color: var(--color-primary);

    svg {
        fill: var(--color-primary);
    }
}

&.Mui-disabled {
    stroke: var(--color-neutral-0);
    stroke-width: 1;
    position: relative;
    
    svg {
        fill: var(--color-neutral-5);
        z-index: 1;
    }

    &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: var(--color-bg-disabled);
    }
}

&.Mui-checked.Mui-disabled {
    stroke: var(--color-border);
    stroke-width: 1;
    
    svg {
        fill: var(--color-border);
    }
}

&.error {
    stroke: var(--color-neutral-0);
    stroke-width: 1; 

    svg {
        fill: var(--color-error);
        stroke: 0.5;
    }
}

svg {
    font-size: var(--font-size-h1);
}
}

//Usando pseudo elementos em todos os checkboxes, escondendo os SVG
span.MuiCheckbox-root{
    height: 24px;
    width: 24px;
    padding: 0;
    color: var(--color-border);

&.Mui-checked {
    color: var(--color-primary);
}

&.Mui-disabled {
    color: var(--color-border);

    svg {
        visibility: hidden;
    }

    &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: var(--color-bg-disabled);
        border-radius: 4px;
        z-index: 2;
    }

    &:after {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        border: 1px solid var(--color-border);
        z-index: 3;
        border-radius: 4px;
    }
}

&.Mui-checked.Mui-disabled {
    color: var(--color-bg-disabled);

    &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: var(--color-neutral-6);
    }

    &:after {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        border: 1px solid var(--color-border);
        z-index: 2;
        border-radius: 4px;
    }
}

&.error {    

    svg {
        visibility: hidden;
    }

    &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: var(--color-neutral-0);
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        border: 1px solid var(--color-error);
        z-index: 2;
        border-radius: 4px;
    }
}

svg {
    font-size: var(--font-size-h1);
}
}*/

