/*! Usefull - Colors Brand */

.background {
    &-primary {
        background-color: var(--color-primary);

        &-lightest {
            background-color: var(--color-red-lightest);
        }
    }

    &-secondary {
        background-color: var(--color-secondary);

        &-lightest {
            background-color: var(--color-blue-lightest);
        }
    }

    &-tertiary{
        background-color: var(--color-tertiary);
    }
}

.background-primary-lightest{
    background-color: var(--color-red-lightest);
}

.background-secondary-lightest{
    background-color: var(--color-blue-lightest);
}

.text {
    &-primary,
    &-primary-darker {
        color: var(--color-primary);
    }

    &-secondary,
    &-secondary-darker {
        color: var(--color-secondary);
    }

    &-tertiary {
        color: var(--color-tertiary);
    }

    &-disabled {
        color: var(--color-tx-disabled);
    }
}

/*! Usefull - Colors Neutral */

$colors-neutral-values: (
    neutral-0 0 "9",
    neutral-1 1 "9",
    neutral-2 2 "9",
    neutral-3 3 "9",
    neutral-4 4 "9",
    neutral-5 5 "0",
    neutral-6 6 "0",
    neutral-7 7 "0",
    neutral-8 8 "0",
    neutral-9 9 "0",
    neutral-10 10 "0",
);

// Background
@each $property, $value, $lightValue in $colors-neutral-values {
    
    .background-#{$property} {
        background-color: var(--color-neutral-#{$value});
    }

    .background-#{$property}-lightest {
        background-color: var(--color-neutral-#{$lightValue});
    }
}


// Text
@each $property, $value in $colors-neutral-values {
    @if ($value < 5) {
        .text-#{$property} {
            color: var(--color-neutral-#{$value});
        }

    } @else {
        .text-#{$property} {
            color: var(--color-neutral-#{$value});
        }

        .text-#{$property}-darker {
            color: var(--color-neutral-#{$value});
        }
    }
}

/*! Usefull - Colors Palette */

$colors-palette-values: (
    "red",
    "orange",
    "yellow",
    "lime",
    "green",
    "teal",
    "cyan",
    "blue",
    "indigo",
    "violet",
    "grape",
    "pink"
);


// Background
@each $value in $colors-palette-values {
    .background {
        &-#{$value} {
            &-lightest {
                background-color: var(--color-#{$value}-lightest);
            }

            &-lighter {
                background-color: var(--color-#{$value}-lighter);
            }

            &-light {
                background-color: var(--color-#{$value}-light);
            }

            & {
                background-color: var(--color-#{$value});
            }

            &-dark {
                background-color: var(--color-#{$value}-dark);
            }

            &-darker {
                background-color: var(--color-#{$value}-darker);
            }

            &-darkest {
                background-color: var(--color-#{$value}-darkest);
            }
        }
    }
}

// Text
@each $value in $colors-palette-values {

    .text {
        &-#{$value} {
            &-lightest {
                color: var(--color-#{$value}-lightest);
            }

            &-lighter {
                color: var(--color-#{$value}-lighter);
            }

            &-light {
                color: var(--color-#{$value}-light);
            }

            & {
                color: var(--color-#{$value});
            }

            &-dark {
                color: var(--color-#{$value}-dark);
            }

            &-darker {
                color: var(--color-#{$value}-darker);
            }

            &-darkest {
                color: var(--color-#{$value}-darkest);
            }
        }
    }
}

/*! Usefull - Colors Semantic */

$colors-semantic-values: (
    "error",
    "warning",
    "success",
    "info"
);

// Background
@each $value in $colors-semantic-values {
    .background {
        &-#{$value} {
            &-light {
                background-color: var(--color-#{$value}-light);
            }

            & {
                background-color: var(--color-#{$value});
            }
        }
    }
}

// Text
@each $value in $colors-semantic-values {
    .text {
        &-#{$value} {
            &-light {
                color: var(--color-#{$value}-light);
            }

            & {
                color: var(--color-#{$value});
            }
        }
    }
}

/*! Usefull - Colors Other */

.background-transparent,
.ie11 .background-transparent {
    background-color: transparent;
}


/*! Usefull - Colors Palette */

$colors-palette-values: (
    "red",
    "orange",
    "yellow",
    "lime",
    "green",
    "teal",
    "cyan",
    "blue",
    "indigo",
    "violet",
    "grape",
    "pink"
);


// Background
@each $value in $colors-palette-values {
    .background {
        &-#{$value} {
            &-lightest {
                background-color: var(--color-#{$value}-lightest);
            }

            &-lighter {
                background-color: var(--color-#{$value}-lighter);
            }

            &-light {
                background-color: var(--color-#{$value}-light);
            }

            & {
                background-color: var(--color-#{$value});
            }

            &-dark {
                background-color: var(--color-#{$value}-dark);
            }

            &-darker {
                background-color: var(--color-#{$value}-darker);
            }

            &-darkest {
                background-color: var(--color-#{$value}-darkest);
            }
        }
    }
}

// Text
@each $value in $colors-palette-values {

    .text {
        &-#{$value} {
            &-lightest {
                color: var(--color-#{$value}-lightest);
            }

            &-lighter {
                color: var(--color-#{$value}-lighter);
            }

            &-light {
                color: var(--color-#{$value}-light);
            }

            & {
                color: var(--color-#{$value});
            }

            &-dark {
                color: var(--color-#{$value}-dark);
            }

            &-darker {
                color: var(--color-#{$value}-darker);
            }

            &-darkest {
                color: var(--color-#{$value}-darkest);
            }
        }
    }
}

