button[class^=MuiButton].MuiFab-root.MuiSpeedDialAction-fab {
    width: 40px;
    height: 40px;
    background-color: var(--color-neutral-0);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: var(--color-primary);
    border: none;
    margin: 0 0 var(--space-base) 0;
    transition-delay: 30ms; 

    svg {
        font-size: var(--font-size-h4);
    }
}

span.MuiSpeedDialIcon-root {
    height: 40px;
}

//Fixed Tooltip/Label

.MuiSpeedDialAction-staticTooltipLabel.MuiSpeedDialAction-staticTooltipLabel {
    color: var(--color-secondary);
    font-size: var(--font-size-s);
    box-shadow: none;
    padding: 0;
    margin-right: var(--space-base);
    line-height: 40px;
    top: 0;
}