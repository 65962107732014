/*! Usefull - Padding */

$padding-values: (
    "none",
    "xs",
    "s",
    "base",
    "m",
    "l",
    "xl",
    "xxl"
);

$padding-position: (
    "top",
    "right",
    "bottom",
    "left",
    "x",
    "y"
);

// Padding
//Use !important to ensure that the padding is always applied in any case.
@each $value in $padding-values {
    .padding-#{$value} {
        padding: var(--space-#{$value}) !important;
    }

    @each $position in $padding-position {
        .padding-#{$position}-#{$value} {
            @if ($position=="x") {
                padding-left: var(--space-#{$value}) !important;
                padding-right: var(--space-#{$value}) !important;
            }

            @else if ($position=="y") {
                padding-bottom: var(--space-#{$value}) !important;
                padding-top: var(--space-#{$value}) !important;
            }

            @else {
                padding-#{$position}: var(--space-#{$value}) !important;
            }
        }
    }
}

.padding-auto {
    padding: 0 auto;
}

// is RTL
@each $value in $padding-values {
    @each $position in $padding-position {

        @if ($position == "left") {
            .is-rtl .padding-#{$position}-#{$value} {
                padding-left: var(--space-none);
                padding-right: var(--space-#{$value});
            }
        }

        @else if ($position == "right") {
            .is-rtl .padding-#{$position}-#{$value} {
                padding-left: var(--space-#{$value});
                padding-right: var(--space-none);
            }
        }
    }
}
