/*! Usefull - Display */

.display {

    &-block {
        display: block;
    }

    &-contents {
        display: contents;
    }
    
}


/*! Usefull - Display Align */

.align {
    &-items {

        &-baseline {
            align-items: baseline;
        }

        &-center {
            align-items: center;
        }

        &-flex-start {
            align-items: flex-start;
        }

        &-flex-end {
            align-items: flex-end;
        }

        &-initial {
            align-items: initial;
        }
    }
}

.top {

    &-left {
        align-items: flex-start;
        justify-content: flex-start;
    }

    &-center {
        align-items: flex-start;
        justify-content: center;
    }

    &-right {
        align-items: flex-start;
        justify-content: flex-end;
    }

}

.center {
    align-items: center;
    justify-content: center;

    &-left {
        align-items: center;
        justify-content: flex-start;
    }

    &-right {
        align-items: center;
        justify-content: flex-end;
    }
}

.bottom {

    &-left {
        align-items: flex-end;
        justify-content: flex-start;
    }

    &-center {
        align-items: flex-end;
        justify-content: center;
    }

    &-right {
        align-items: flex-end;
        justify-content: flex-end;
    }
}


/*! Usefull - Display Flex */

.display-flex {
    display: flex;
}

.flex {
    &1 {
        flex: 1;
    }

    &2 {
        flex: 2;
    }

    &3 {
        flex: 3;
    }

    &-direction {

        &-column {
            flex-direction: column;
        }

        &-column-reverse {
            flex-direction: column-reverse;
        }

        &-row {
            flex-direction: row;
        }

        &-row-reverse {
            flex-direction: row-reverse;
        }
    }
}

.justify {

    &-content {

        &-flex-end {
            justify-content: flex-end;
        }

        &-center {
            justify-content: center;
        }

        &-left {
            justify-content: left;
        }

        &-space-between {
            justify-content: space-between;
        }
    }
}

.flex-direction-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-direction-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}