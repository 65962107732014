.menu {
    
    .MuiPaper-root {
        width: fit-content;
        filter: drop-shadow(0px 1px 12px rgba(22, 34, 51, 0.15));
        border-radius: 8px;
        color: var(--color-neutral-7);
        font-size: var(--font-size-s);

        .MuiList-root {
            border: none;
            padding: 0;

            .MuiMenuItem-root {
                font-size: var(--font-size-s);
                color: var(--color-neutral-7);
                padding: 0;
                height: var(--btn-and-input-height);

                &:hover {
                    color: var(--color-secondary);
                    background-color: var(--color-neutral-0);

                    &:last-child {
                        border-radius: 0 0 8px 8px;
                    }

                    &:first-child {
                        border-radius: 8px 8px 0 0;
                    }

                    .MuiListItemIcon-root svg {
                        color: var(--color-secondary);
                        background-color: var(--color-neutral-0);
                    }
                }

                &:active {
                    color: var(--color-primary);
                    background-color: var(--color-neutral-0);

                    &:last-child {
                        border-radius: 0 0 8px 8px;
                    }

                    &:first-child {
                        border-radius: 8px 8px 0 0;
                    }

                    .MuiListItemIcon-root svg {
                        color: var(--color-primary);
                        background-color: var(--color-neutral-0);
                    }
                }

                .MuiListItemText-root span {
                    font-size: var(--font-size-s);
                    padding-right: var(--space-base);
                }

                .MuiListItemIcon-root {
                    min-width: 24px;
                    padding: 0 var(--space-s) 0 var(--space-base);

                    svg {
                        font-size: 16px;
                    }
                }        
            } 
        }
    }

    .MuiTouchRipple-ripple {
        color:var(--color-bg-pressed);
    }

}