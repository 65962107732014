span[class^="MuiSlider"] {
    color: var(--color-primary);

    .Mui-focusVisible {
        box-shadow: none;
    }

    .MuiSlider-rail {
        border-radius: 45px;
        color: var(--color-border);
    }

    .MuiSlider-thumb {
        width: 24px;
        height: 24px;
        background-color: var(--color-neutral-0);
        border: var(--border-size-s) solid var(--color-neutral-6);
        transition: all 150ms ease-out;

        &.Mui-active {
            box-shadow: none;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            height: 10px;
            width: 1px;
            border-left: 1px solid var(--color-neutral-6);
            background: var(--color-neutral-0);
            box-shadow: none;
            border-radius: 0;
            left: 7px;
            top: 6px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            height: 10px;
            width: 3px;
            border-left: 1px solid var(--color-neutral-6);
            border-right: 1px solid var(--color-neutral-6);
            border-radius: 0;
            left: 13px;
            top: 11px;
        }

        &:active,
        &:hover {
            box-shadow: none;
            border-color: var(--color-primary);

            &:before,
            &:after {
                border-color: var(--color-primary);
            }
        }

        &:focus,
        &:focus-visible {
            box-shadow: 0px 0px 0px 2px rgba(79, 164, 238, 0.5);
            border-color: var(--color-border-focus);
        }
    }

    &.MuiSlider-marked {

        .MuiSlider-mark {
            color: var(--color-border);
            top: 22px;
            height: 4px;            
        }
        
        .MuiSlider-markLabel {
            color: var(--color-neutral-8);
            font-size: var(--font-size-s);
            margin-top: -5px;
        }

        .MuiSlider-valueLabel {
            background-color: transparent;
            line-height: 1;
            top: -3px;

            &:before {
                display: none;
            }
           
            .MuiSlider-valueLabelLabel {
                color: var(--color-secondary);
                font-size: var(--font-size-s);
            }
        }
    }

    &.Mui-disabled {

        .MuiSlider-track {
            color: var(--color-neutral-6);
        }

        .MuiSlider-thumb {
            background-color: var(--color-bg-disabled);
        }  
    } 
    
    &.small {
        .MuiSlider-rail {
            height: 2px;
            border-radius: 45px;
        }

        .MuiSlider-thumb {
            width: 12px;
            height: 12px;
    
            &:before {
                display: none;
            }
    
            &:after {
                display: none;
            }
        }

        .MuiSlider-track {
            height: 2px;
        }
    }

    &.secondary {
        .MuiSlider-rail,
        .MuiSlider-track {
            color: var(--color-secondary);
        }

        .MuiSlider-thumb {
            
            &:active,
            &:hover {
                box-shadow: none;
                border-color: var(--color-secondary);

                &:before,
                &:after {
                    border-color: var(--color-secondary);
                }
            }
        }
    }
}

