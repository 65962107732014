[class^=MuiDialog] {
    padding: var(--space-m);

    [class*=MuiDialogTitle] {
        font-size: var(--font-size-h2);
        color:var(--color-neutral-10);
    }

    [class*=MuiDialogContentText] {
        color:var(--color-neutral-10);
        font-size: var(--font-size-s);
    }

// Check margins, padding and background in "-_divider.scss
    .MuiDivider-root {
        margin: 0 var(--space-m);
    }

    .MuiDialogActions-root {
        display: flex;
    }

    .MuiDialog-paper {
        box-shadow: var(--shadow-xl);
        max-width: 500px;
    }

    [class*=MuiBackdrop]{
        background-color: var(--overlay-background);
    }
}
