.MuiPaper-root {
    box-shadow: var(--shadow-m);
}

.MuiPaper-root.outline {
    border: var(--border-size-s) solid var(--color-border);
}



