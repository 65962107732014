.MuiDivider-root {
  &::before,
  &::after {
    border-top: var(--border-size-s) solid var(--color-border);
  }

  $colors: ("success", "error", "info", "warning", "primary", "secondary");

  @each $color in $colors {
    &.background-#{$color} {
      background-color: transparent;

      &::before,
      &::after {
        border-top: var(--border-size-s) solid var(--color-#{$color});
      }
    }
  }

  $neutralColors: (
    0 "0",
    1 "1",
    2 "2",
    3 "3",
    4 "4",
    5 "5",
    6 "6",
    7 "7",
    8 "8",
    9 "9",
    10 "10"
  );

  @each $value, $color in $neutralColors {
    background-color: transparent;

    &.background-neutral-#{$color} {
      background-color: transparent;

      &::before,
      &::after {
        border-top: var(--border-size-s) solid var(--color-neutral-#{$color});
      }
    }
  }

  &.text-align-left {
    &::before {
      content: "";
      width: 10%;
    }
  }

  &.text-align-right {
    &::after {
      content: "";
      width: 10%;
    }
  }
}

//Default Version
hr.MuiDivider-root {
  border-bottom: var(--border-size-s) solid var(--color-border);

  $colors: ("success", "error", "info", "warning", "primary", "secondary");

  @each $color in $colors {
    &.background-#{$color} {
      background-color: transparent;
      border-bottom: var(--border-size-s) solid var(--color-#{$color});
    }
  }

  $neutralColors: (
    0 "0",
    1 "1",
    2 "2",
    3 "3",
    4 "4",
    5 "5",
    6 "6",
    7 "7",
    8 "8",
    9 "9",
    10 "10"
  );

  @each $value, $color in $neutralColors {
    background-color: transparent;

    &.background-neutral-#{$color} {
      background-color: transparent;
      border-bottom: var(--border-size-s) solid var(--color-neutral-#{$color});
    }
  }
}
