/*! Usefull - Shadow */

$shadow-values: (
    "none",
    "xs",
    "s",
    "m",
    "l",
    "xl"
);

@each $value in $shadow-values {
    .shadow-#{$value} {
        box-shadow: var(--shadow-#{$value}) !important;
    }
}
