
.layout {

    &-grid-container {
        max-width: 1280px;
        padding: var(--space-none) var(--space-xl);
        margin: var(--space-none) auto;
        width: 100%;
    }

    .hamburguer-menu {
        display: none;
    }

    &-top {

        .app-menu,
        .app-menu-overlay {
            display: none;
        }

        display: grid;
        grid-template-rows: auto 1fr auto;
        min-height: 100vh;

        &__header { //header section
            grid-row: 1;
            background-color: var(--header-color);
            box-shadow: 0 1px 5px 0 rgba(21, 24, 26, 0.1);
            z-index: 100;
            height: var(--header-size);
            position: fixed;
            width: 100%;

            &-container {
                display: flex;
                align-items: center;
                height: 100%;
            }

            &-application-name {
                margin-right: 80px;
                display: flex;
                align-items: center;
                font-weight: var(--font-bold);
                font-size: var(--font-size-base);

                img {
                    height: 28px;
                    margin-right: var(--space-base);
                }
            }

            &-menu {

                height: 100%;

                .app-menu-container {

                    flex: 1;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    a {
                        margin-right: var(--space-m);
                        position: relative;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: var(--color-neutral-7);

                        &.is-active {
                            color: var(--color-primary);
                        }

                        &.is-active:before,
                        &:before{
                            content: "";
                            width: 100%;
                            height: 4px;
                            border-top-left-radius: 4px;
                            border-top-right-radius: 4px;
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                            transition: all 150ms ease-in-out;
                            background-color: transparent;
                        }

                        &.is-active:before {
                            background-color: var(--color-primary);
                            bottom: 0px;
                        }
                        &:hover {
                            &:before{
                                background-color: var(--color-primary);
                                bottom: 0px;
                            }
                        }
                    }
                }
            }

        }

        &__content { //content section
            overflow-y: auto;
            grid-row: 2;
            background-color: var(--color-background-body);
            padding-top: var(--header-size);

            .layout-grid-container {
                padding-top: var(--space-l);
                padding-bottom: var(--space-l);
            }

            .breadcrumbs {
                margin-bottom: var(--space-base);

                &:empty {
                    display: none;
                }
            }

            .content-top {
                display: flex;
                justify-content: space-between;

                h1,
                &__actions {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                }

                h1:empty,
                &__actions:empty,
                &__title:empty {
                    display: none;
                }
                
                h1 {
                    flex: 1;
                    margin-bottom: var(--space-m);
                    font-size: var(--font-size-h1);                
                }

                &__title {
                    flex: 1;
                    margin-bottom: var(--space-m);
                }

                &__actions {
                    flex: 1;
                    justify-content: flex-end;
                    margin-bottom: var(--space-m);
                    text-align: right;
                }
            }

            .main-content {
                position: relative;

                .layout-top--half-width & {
                    width: 50%;
                }

            }

        }

        &__footer { //footer section
            grid-row: 3;
            background-color: var(--color-background-body);
            padding-top: var(--space-base);
            padding-bottom: var(--space-base);
        }

    }

}

/* Tablet and Phone Devices */
@media (max-width: $tablet-width) {

    :root {
        --header-size: 56px;
    }

    .layout {

        &-grid-container {
            padding: var(--space-base);
        }

        &-top{

            //app header on mobile
            &__header {

                &-container {
                    justify-content: space-between;
                }

                &-menu,
                &-actions {
                    display: none;
                }

            }

            &__content {

                .main-content {

                    .layout-top--half-width & {
                        width: 100%;
                    }

                }

            }

            //mobile app menu
            .app-menu {

                height: 100%;
                background-color: var(--color-neutral-0);
                bottom: 0;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: auto;
                position: fixed;
                top: 0;
                width: var(--side-menu-size);
                z-index: 105;
                left: calc(-1 * var(--side-menu-size));
                transform: translateX(0) translateZ(0);
                will-change: transform;

                &-overlay {

                    background-color: var(--overlay-background);
                    height: 100vh;
                    left: 0;
                    opacity: 0;
                    pointer-events: none;
                    position: fixed;
                    top: 0;
                    transition: opacity 130ms ease-in;
                    width: 100vw;
                    will-change: opacity;
                    z-index: 104;

                }

                &__header {
                    
                    margin: var(--space-base) var(--space-m);
                    display: flex;
                    justify-content: space-between;
                    height: 56px;

                    &-logo {
                        img {
                            height: 22px;
                        }
                        line-height: 1;
                    }

                    &-name {
                        color: var(--color-neutral-7);
                        font-size: var(--font-size-s);
                        
                    }

                    &-close {
                        width: 16px;
                        height: 16px;
                        position: relative;

                        &:before,
                        &:after {
                            content: "";
                            position: absolute;
                            background-color: var(--color-primary);
                            left: 0;
                            height: 1px;
                            width: 22px;
                            transform-origin: 0 0;
                            position: absolute;
                        }

                        &:before {
                            transform: rotate(45deg);
                            top: 0;

                        }

                        &:after {
                            bottom: 0;
                            transform: rotate(-45deg);
                        }
                    }
                }

                &__content {

                    flex: 1;
                    padding-top: var(--space-s);

                    .app-menu-container {
                        display: flex;
                        flex-direction: column;

                        > a {
                            padding: var(--space-s) var(--space-m);
                            margin-bottom: var(--space-base);
                            position: relative;
                            font-size: var(--font-size-s);
                            width: 190px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-decoration: none;
                            color: var(--color-neutral-7);

                            &.is-active {

                                color: var(--color-primary);

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 2px;
                                    height: 100%;
                                    background-color: var(--color-primary);
                                }
                            }

                        }
                    }

                }

                &__content-bottom {
                    padding: var(--space-m);
                    color: var(--color-neutral-7);
                }

                &__footer {
                    border-top: var(--border-size-s) solid var(--color-neutral-3);
                    height: var(--header-size);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .app-menu-overlay {
                display: block;
            }

            &.app-menu-visible {
                .app-menu-overlay {
                    opacity: 1;
                    pointer-events: auto;
                }

                .app-menu {
                    transform: translateX(calc(-1 * var(--side-menu-size))) translateZ(0);
                    transition: all 300ms ease-in-out;
                    left: auto;
                    right: calc(-1 * var(--side-menu-size));
                }
            }

        }

    }

    .hamburguer-menu {
        border-bottom: 2px solid var(--color-neutral-10);
        border-top: 2px solid var(--color-neutral-10);
        position: relative;
        width: 20px;
        height: 20px;

        &:before {
            content: "";
            background-color: var(--color-neutral-10);
            height: 2px;
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

/* Phone Portrait Only Styles */
@media (max-width: $phone-width) {

    .layout-top__header-application-name {
        margin-right: 80px;
    }
}

/* Tablet Only Styles */
@media (min-width: $phone-width) and (max-width: $tablet-width) {

}


