.MuiBottomNavigation-root {

    .MuiButtonBase-root {
        color:var(--color-neutral-7);
        border: none;
        border-radius: var(--border-radius-none);
        height:56px;

        &:hover {
            background-color: var(--color-bg-hover);
            filter: none;
            box-shadow: none;
            border-radius: var(--border-radius-none);
        }
    }

    [class*=-MuiButtonBase-root-MuiBottomNavigationAction-root] {
       
        &.Mui-selected {
            color: var(--color-primary);
        }
    }

    [class*=MuiBottomNavigationAction-label] {
        font-size: var(--font-size-s);
        opacity: 1;
    }

    .MuiTouchRipple-ripple {
        color:var(--color-bg-pressed);
    }
}