/*! Usefull - Text */

.break-word {
    word-break: break-word;
}

.text-align {

    &-left {
        text-align: left;
    }
    
    &-center {
        text-align: center;
    }
    
    &-right {
        text-align: right;
    }
}

.white-space-nowrap {
    white-space: nowrap;
}

.white-space-preline {
    white-space: pre-line;
}

// is RTL
.is-rtl {

    [align=right],
    .text-align-right {
        text-align: left;
    }

    [align=left],
    .text-align-left {
        text-align: right;
    }
}
