.MuiTooltip-popper {

    .MuiTooltip-tooltip {
        background-color: var(--color-secondary);
        height: 34px;
        font-size: var(--font-size-s);
        padding: 0 var(--space-base);
        display: inline-flex;
        align-items: center;
        width: fit-content;
        position: relative;

      &.MuiTooltip-tooltipPlacementTop:after {
            content: " ";
            border-left: solid transparent 8px;
            border-right: solid transparent 8px;
            border-top: solid var(--color-secondary) 8px;
            bottom: -7px;
            height: 0;
            left: 50%;
            transform: translateX(-8px);
            position: absolute;
            width: 0;
        }

        &.MuiTooltip-tooltipPlacementRight:after {
            content: " ";
            border-left: solid transparent 8px;
            border-right: solid transparent 8px;
            border-top: solid var(--color-secondary) 10px;
            height: 0;
            right: 100%;
            position: absolute;
            width: 0;
            transform: rotate(90deg);
            margin-right: -4px;
        }

        &.MuiTooltip-tooltipPlacementLeft:after {
            content: " ";
            border-left: solid transparent 8px;
            border-right: solid transparent 8px;
            border-top: solid var(--color-secondary) 10px;
            height: 0;
            left: 100%;
            position: absolute;
            width: 0;
            transform: rotate(-90deg);
            margin-left: -4px;
        }

        &.MuiTooltip-tooltipPlacementBottom:after {
            content: " ";
            border-left: solid transparent 8px;
            border-right: solid transparent 8px;
            border-top: solid var(--color-secondary) 8px;
            top: -7px;
            height: 0;
            right: 50%;
            transform: translateX(8px) rotate(180deg);
            position: absolute;
            width: 0;
        }
    } 
}

.MuiTooltip-popper[data-popper-placement="top-start"] .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop:after {
    transform: translateX(0);
    left: 10px;
}

.MuiTooltip-popper[data-popper-placement="top-end"] .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop:after {
    left: 100%;
    transform: translateX(-26px);
}

.MuiTooltip-popper[data-popper-placement="bottom-start"] .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom:after {
    transform: translateX(0) rotate(180deg);
    left: 10px;
}

.MuiTooltip-popper[data-popper-placement="bottom-end"] .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom:after {
    left: 100%;  
    transform: translateX(-26px) rotate(180deg);
}


