* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    color: var(--color-neutral-10);
    //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-family: var(--font-family);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -servicestudio-overflow: hidden;
}

body {
    background-color: var(--color-background-body);
    direction: ltr;
    font-size: var(--font-size-s);
    line-height: 1.5;
    margin: 0;
}

html,
body {
    height: 100%;
}

#root {
    height: 100%;
}

.ph:empty,
.hidden {
    display: none;
}

:focus {
    outline: 0;
}

::-moz-focus-inner {
    border: 0;
}

abbr[title] {
    border-bottom: 0;
    text-decoration: underline;
}

div[onclick] {
    cursor: pointer;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
}

/*
label {
    color: var(--color-neutral-8);
}

[data-label] {
    &.mandatory {
        &:after {
            color: var(--color-error);
            margin-left: var(--space-xs);
        }
    }
}

input,
textarea,
select,
button {
    appearance: none;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.25;
    margin: 0;
}

input {

    &[type=checkbox],
    &[type=radio] {
        border: 0;
        padding: 0;
    }

    &[type=number] {

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            height: auto;
        }
    }

    &[type=search] {
        appearance: textfield;
        outline-offset: -2px;

        &::-webkit-search-cancel-button,
        &::-webkit-search-decoration {
            appearance: none;
        }
    }

    &[type=time],
    &[type=date],
    &[type=datetime],
    &[type=datetime-local] {
        display: inline-flex;
    }

    &[type=time] {
        &:empty {
            display: inline-flex;
        }
    }
}
*/
::-webkit-file-upload-button {
    appearance: button;
}

select::-ms-expand,
input::-ms-clear {
    display: none;
}