.MuiBreadcrumbs-li {
    span {
        color: var(--color-neutral-7);
        font-size: var(--font-size-s); 
    }  

    a,
    a.MuiLink-root {
        color: var(--color-link);
        text-decoration: none;
        font-size: var(--font-size-s);
        cursor: pointer;

        &:hover {
            color: var(--color-secondary);
        }
    }
}

li.MuiBreadcrumbs-separator {
    color: var(--color-neutral-5);
}