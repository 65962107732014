.MuiBadge-root {
  .MuiBadge-badge {
    color: var(--color-neutral-0);
    background-color: var(--color-primary);
    line-height: 10px;
  }

  $colors: (
    "success",
    "error",
    "info",
    "warning",
    "primary",
    "secondary",
    "neutral-10",
    "neutral-9",
    "neutral-8",
    "neutral-7",
    "neutral-6",
    "neutral-5",
    "neutral-4",
    "neutral-3",
    "neutral-2",
    "neutral-1",
    "neutral-0"
  );

  @each $color in $colors {
    &.background-#{$color} {
      background-color: transparent;

      .MuiBadge-badge {
        background-color: var(--color-#{$color});
      }
    }
  }
}
