
[class*=MuiSnackbar] {

    button[class^=MuiButton] {
        border: none;
        background-color: inherit;
        color:inherit;
        padding-right: var(--space-xs);

            &:hover{
                box-shadow: none;
            }
    }
}


