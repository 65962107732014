
[class*=MuiAlert-root] {
    box-shadow: var(--shadow-s);

    .MuiSvgIcon-root {
        margin-right: var(--space-base);
        vertical-align:text-top;
    } 

    .MuiAlertTitle-root {
        margin-top: 0;
        margin-bottom: 0; 
    }

    .MuiAlert-message {
        display: flex;
        align-items: center;
    }

    .MuiAlert-icon {
        display:none;
    }

    .MuiAlert-action {
        padding: 0;
        
        button {
            padding-right: var(--space-xs);
            svg {
                margin-right: 0;
            }
        }
    }

    &.error {
        background-color: var(--color-error-light);   
        color:var(--color-error);
        border-left:var(--border-size-l) solid var(--color-error);
    }

    &.info {
        background-color: var(--color-info-light);
        color:var(--color-info);
        border-left:var(--border-size-l) solid var(--color-info);
    }
    
    &.success {
        background-color: var(--color-success-light);
        color:var(--color-success);
        border-left:var(--border-size-l) solid var(--color-success);
    }
    
    &.warning {
        background-color: var(--color-warning-light);
        color:var(--color-warning);
        border-left:var(--border-size-l) solid var(--color-warning);
    }

    &.description {
        .MuiAlert-message {
            align-items: flex-start;

            .MuiTypography-root {
                line-height: 22px;
            }
        }
    }
}


