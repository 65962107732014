
.disabledSelected{
    background-color: black;
}

[class^="MuiToggleButtonGroup"] {

    background-color: transparent;
    box-sizing: border-box;    

        [class*="MuiButtonBase"] {

            &[class*="MuiToggleButton"] {
                color: var(--color-neutral-7);

                &:hover {
                    background-color: var(--color-bg-hover);
                    box-shadow: none;
                    color: var(--color-primary);
                }

                &:active {
                    border: var(--border-size-s) solid var(--color-bg-pressed);
                }

                &:focus {
                    border: var(--border-size-s) solid var(--color-border-focus);
                }
    
                &.Mui-selected {
                    background-color: var(--color-primary);
                    border: var(--border-size-s) solid var(--color-primary);
                    border-right-color: transparent;
                    color:  var(--color-neutral-0);
                }

                &[class*="Mui-disabled"] {
                    border: var(--border-size-s) solid var(--color-neutral-6);
                    background-color: var(--color-neutral-2);
                    color: var(--color-neutral-6);
            
                    &.Mui-selected {
                        background-color:var(--color-neutral-4);
                    }
                }
            }
        }
    
    .MuiToggleButtonGroup-grouped:not(:first-of-type) {
        margin-left: 0;

        &.Mui-disabled {
            margin-left: -1px;

            &.Mui-selected {
                margin-left: -1px;
            }
        }
    }
    
    &.small {
        button[class^=MuiButton] {
            height: 32px;
        }
    }

    &.large {
        button[class^=MuiButton] {
            height: 48px;
        }
    }
}

//Multiple selection group border fix to avoid "shrinking"
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected {
    border-left: 1px solid var(--color-primary);
}