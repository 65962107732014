[class*="MuiFormControl"] {    
       
    &.MuiTextField-root {
        margin-bottom: var(--space-m);
        background-color: var(--color-neutral-0);

        .MuiFormLabel-root {
            color: var(--color-neutral-6);
            font-size: var(--font-size-s);
            line-height: 1;
            top: -3px;
            transform: translate(14px, 16px) scale(1);  
            
            &.MuiInputLabel-root {
                color: var(--color-neutral-6);
            }

            &.MuiInputLabel-shrink {
                transform: translate(28px, -3px) scale(0.75);
                color: var(--color-neutral-8);
            }

            &.Mui-disabled {
                color: var(--color-neutral-6);
            }

            &.Mui-error {
                color: var(--color-neutral-8);
            }
        }

        .MuiInputBase-root {
            height: var(--btn-and-input-height);
            color: var(--color-secondary);

            ::-webkit-input-placeholder {
                color: var(--color-neutral-6);
                opacity: 1;
             }
             
             :-moz-placeholder { /* Firefox 18- */
                color: var(--color-neutral-6);
                opacity: 1; 
             }
             
             ::-moz-placeholder {  /* Firefox 19+ */
                color: var(--color-neutral-6);
                opacity: 1;  
             }
             
             :-ms-input-placeholder {  
                color: var(--color-neutral-6);
                opacity: 1;  
             }

            fieldset {
                legend {
                    font-size: 0.7em;
                }
            }

            &.Mui-error {
                position: relative;

                fieldset {
                    border-color: var(--color-error);
                }
            }

            .MuiInputBase-input {
                font-size: var(--font-size-s); 
                padding-left: var(--space-base);   
                padding-right: var(--space-base);
            }

            &.Mui-disabled {
                background-color: var(--color-bg-disabled);
            }
        }
    }

    .MuiFormHelperText-root {
        color: var(--color-neutral-7);
        margin-left: 0;

        &.Mui-error {
            color: var(--color-error);
        }
    }

    .MuiInputBase-root.MuiInputBase-multiline {
        height: auto;
        padding-left: 0;
        padding-right: 0;
    }
}   

//Global asterisk styling for all inputs, text fields, selects
.MuiFormLabel-asterisk {
    color: var(--color-error);
}

//Password Input Styling

.password.MuiInputBase-root {
    height: var(--btn-and-input-height);
    border: var(--border-size-s) solid var(--color-border);
    border-radius: var(--border-radius-soft);
    padding: var(--space-base);
    background-color: transparent;   
    font-size: var(--font-size-s); 

    &:hover {
        border: var(--border-size-s) solid var(--color-border-hover);
    }

    &.Mui-focused {
        border-color : var(--color-border-focus) !important;
    }

    &::before,
    &::after {
        border-bottom: none;

        :hover & {
            border-bottom: none; 
        }
    }
}


//Label for Input with Fixed Label (Input Label component is outside of FormControl or TextField)
label.MuiFormLabel-root {
    font-size: var(--font-size-s);
    color: var(--color-neutral-7);
    margin-bottom: var(--space-s);
    text-align: left;
}

// Input borders
.MuiOutlinedInput-notchedOutline{
    border-color: var(--color-border);
    padding: 12px 21px;

    .Mui-focused & {
        border-color : var(--color-border-focus) !important;
    }

    .MuiOutlinedInput-root:hover & {
        border: var(--border-size-s) solid var(--color-border-hover);
    }
}

.Mui-focused label,
label.Mui-focused {
    color : var(--color-border-focus) !important; 
}

//Styling for SVG's inside inputs (including Password input)

.MuiInputAdornment-root {
    svg {
        fill: var(--color-neutral-6);
    }
                    
    .MuiButtonBase-root.MuiIconButton-root {
        border: none;
        padding: 0;
        background-color: transparent;

        &:hover {
            background-color: transparent;
            box-shadow: none;
        }

        svg {
            fill: var(--color-neutral-6);

            &:hover {
                fill: var(--color-primary);
            }
        }
    }
}

@media (max-width: 1024px) {
    
    .MuiTextField-root {
        width: 100%;
    }
}

