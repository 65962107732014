button[class^="MuiButton"].MuiFab-root {
    height: 56px;
    width: 56px;
    padding: 16px;
    background-color: var(--color-primary);
    color: var(--color-neutral-0);
    border-radius: var(--border-radius-circle);
    transform: rotate(0deg) translateZ(0);
    transform-origin: center center;
    transition: all 180ms linear;       

    &.icon {
        svg {
            font-size: 28px;          
        }
    }

    &.extended {
        height: var(--btn-and-input-height);
        width: fit-content;
        padding: 0 var(--space-base);
        border-radius: 100px;
        font-size: var(--font-size-s);
        text-transform: uppercase;

        svg {
            font-size: 28px;
            margin-right: var(--space-s);
        
            &:hover {
                animation: none;
            }
        }
    }

    svg {
        font-size: 40px;
    }
}