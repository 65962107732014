a.MuiLink-root {
    color: var(--color-link);
    font-size: var(--font-size-base);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    width: fit-content;
    background: linear-gradient(currentcolor, currentcolor) 0px 100%/0px var(--border-size-s) no-repeat;

    &:hover {
        color: var(--color-link-hover);
    }

    &:active {
        color: var(--color-secondary);
    }   

    &:focus {
        color: var(--color-link-focus);
    }

    &.underlined-hover {
        &:hover {
            background-size: 100% var(--border-size-s);
            color: var(--color-link-hover);
        }

        &:active {
            color: var(--color-secondary);
        }   
    
        &:focus {
            color: var(--color-link-focus);
        }
    }

    &[disabled] {
        color: var(--color-neutral-6);
        cursor: initial;
    }
}