.popper {

    .MuiPaper-root {
        font-family: var(--font-family);
        border: var(--border-size-s) solid var(--color-border);
        border-radius: var(--border-radius-soft);
        padding: var(--space-s) var(--space-base);
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
    }
}
