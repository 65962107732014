form {
    display: flex;
    flex-direction: column;
}

/* Date Picker */

.date-picker {

    .MuiSvgIcon-root{
        margin-right: var(--space-base);  
        font-size: var(--font-size-s);  
    }

    button[class^=MuiButton]:hover{
        background-color: transparent;
        box-shadow: none;
        .MuiSvgIcon-root{
            fill:var(--color-neutral-6)
        }
    }
} 

/* Date Calendar Component */

[class*=MuiDateCalendar-root] {

    .MuiPickersCalendarHeader-labelContainer {
        color:var(--color-primary);

        .MuiSvgIcon-root {
            display: none;
        }
    }
   

    button[class^=MuiButton] {
        height:36px;
        border: hidden ;
        color:var(--color-neutral-9);
        border-radius: 100%;
        &:hover {
            box-shadow: none;
            background-color: transparent; 
        }
        .MuiSvgIcon-root {
            color: var(--color-primary);
            &:hover {
                 
                border-radius:var(--border-radius-circle); ;
                background-color:var(--color-neutral-2);
            }
          
        }
    }

    .MuiPickersDay-root {
        font-size: var(--font-size-s);
        &:hover{
            border:1px solid var(--color-border-hover);
            background-color: transparent;
            box-shadow: none;
        }
        &.Mui-selected {
            border-color: var(--color-primary) !important;
            background-color: var(--color-primary);
            color: var(--color-neutral-0);

            &:hover {
                background-color: var(--color-primary); ;
           }
        }
    }

    .MuiPickersDay-today {
        border:1px solid var(--color-neutral-4) !important;
        background-color: transparent;
        &:hover {
            background-color: var(--color-bg-hover) !important;
            color: var(--color-primary);
        }
    }
    
   .MuiPickersYear-yearButton.Mui-selected {
        background-color: var(--color-primary);

   }
    
}
