button[class^="MuiButton"] {
    font-family: var(--font-family);
    height: var(--btn-and-input-height);
    padding: 10px 16px;
    text-transform: none;
    background-color: var(--color-neutral-0);
    color: var(--color-primary);
    border: var(--border-size-s) solid var(--color-neutral-5);
    border-radius: var(--border-radius-soft);
    transition: filter 200ms ease-in-out;

    &:hover {
        background-color: var(--color-bg-hover);
    }

    &:active {
        background-color: var(--color-bg-pressed);
    }

    &:focus {
        border-color: var(--color-border-focus);
    }
    
    &[disabled].Mui-disabled{
        color: var(--color-neutral-6);
        background-color: var(--color-bg-disabled);
        border: var(--border-size-s) solid var(--color-border);
    }

    &.primary{
        color: #FFF;
        background-color: var(--color-primary);
        border: var(--border-size-s) solid var(--color-primary);

        &:hover {
            background-color: var(--color-primary-hover);
        }

        &:active {
            box-shadow: inset 0px 250px 0px rgba(16, 18, 19, 0.22);
        }
    
        &:focus {
            border: var(--border-size-s) solid var(--color-border-focus);
        }
    }

    &.cancel{
        background-color: var(--color-neutral-0);
        color: var(--color-neutral-7);
        border: var(--border-size-s) solid var(--color-neutral-4);

        &:hover {
            background-color: var(--color-neutral-3);
        }

        &:active {
            box-shadow: inset 0px 250px 0px rgba(16, 18, 19, 0.22);
        }
    
        &:focus {
            border: var(--border-size-s) solid var(--color-border-focus);
        }
    }

    &.success{
        color: var(--color-neutral-0);
        background-color: var(--color-success);
        border: var(--border-size-s) solid var(--color-success);

        &:hover {
            background-color: rgb(38, 119, 55);
        }
    
        &:active {
            box-shadow: inset 0px 250px 0px rgba(16, 18, 19, 0.22);
        }
    
        &:focus {
            border: var(--border-size-s) solid var(--color-border-focus);
        }
    }

    &.error{
        color: var(--color-neutral-0);
        background-color: var(--color-error);
        border: var(--border-size-s) solid var(--color-error);

        &:hover {
            background-color: rgb(200, 31, 31);
        }

        &:active {
            box-shadow: inset 0px 250px 0px rgba(16, 18, 19, 0.22);
        }
    
        &:focus {
            border: var(--border-size-s) solid var(--color-border-focus);
        }
    }


    // sizes

    &.small{
        height: 32px !important;
    }

    &.large{
        height: 48px !important;
    }

    .MuiTouchRipple-ripple{
        color:var(--color-bg-pressed);
        filter: brightness(0.5);
    }
}