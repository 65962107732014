
.container-content-flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-wrapper-flex{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;

    button.MuiButtonBase-root {
        margin-bottom: var(--space-s);
    }
}

.transfer-list-container{
    min-width: 200px;
    min-height: 230px;
    overflow: auto;
    margin: var(--space-l);
}