button.MuiTab-root {
    border: none;
    color: var(--color-neutral-7);
    padding: var(--space-base) var(--space-m);
    background-color: transparent;
    position:relative;
    

    &.Mui-selected {
        color: var(--color-secondary);

        &:after {
            content: "";
            height: 2px;
            background-color: var(--color-primary);
            position: absolute;
            width: 100%;
            bottom: -1px;
        } 

        &:hover {
            box-shadow: none;
            background-color: transparent;
            color: var(--color-secondary);   
            
            &:after {
                background-color: var(--color-primary);
            } 
        }
    }

    &:hover {
        color: var(--color-secondary);
        box-shadow: none;
        background-color: transparent;
    }
}


.MuiTabs-indicator {
    background-color: transparent;
}

.MuiTabs-flexContainer {
    border-bottom: var(--border-size-s) solid var(--color-border);

    &.MuiTabs-flexContainerVertical {
        border-right: var(--border-size-s) solid var(--color-border);
    }
}

.MuiButtonBase-root.MuiTab-root {
    overflow: visible;
}

div[role='tabpanel'] {
    font-family: var(--font-family);
}

//Vertical Tabs

.MuiTabs-root.MuiTabs-vertical {

    .Mui-selected {

        &:after {
            content: "";
            width: 3px;
            background-color: var(--color-primary);
            position: absolute;
            height: 100%;
            right: -1px;
        } 
    }

    .MuiTabs-scroller {
        border: none;
    }

    svg path {
        fill: var(--color-neutral-7);
    }
}