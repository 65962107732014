/* Rating */
span[class^="MuiRating"]{
    color: var(--color-border);

    svg {
        font-size: var(--font-size-base);
    }

    &.Mui-disabled {
        opacity: 0.5;
    }

    .MuiRating-iconFilled {
        color: var(--color-primary);
    }

    &.small {
       
        svg {
            font-size: 8px;
        }
    }

    &.large {
      
        svg {
            font-size: 24px;
        }
    }
}