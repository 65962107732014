.MuiModal-root {
    
    &.dialog-style {

        .MuiBox-root {
            font-family: var(--font-family);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: var(--border-radius-soft);
            padding: var(--space-m);
            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
        }
    }
}


  