
[class*=MuiFormControl-root]{
    
    .MuiFormLabel-root{
        &.MuiInputLabel-shrink {
            transform: translate(28px, -8px) scale(0.75);
        }

        .MuiFormLabel-asterisk {
            color: var(--color-error);
        }
    } 

     //CSS FOR SINGLE SELECT
     [class*=MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root] {
        height: 40px;
  
    }

.MuiFormLabel-root{
    transform: translate(14px, 10px) scale(1);

    &.MuiInputLabel-shrink {
        transform: translate(27px, -8px) scale(0.75);
    }
}   

//CSS FOR MULTIPLE SELECT
[class*=MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input]{
    
    &.MuiSelect-select {
    line-height:7px !important;
    min-height: auto;

    }

}
}

[class*=MuiButtonBase-root-MuiMenuItem-root].Mui-selected{
    background-color: var(--color-bg-selected);
}

[class*=MuiFormControl-root]{

    
}

//CHIP SAMPLE - set display of selected chips inside input
.MuiFormControl-root {

    .MuiInputBase-root {

        .MuiSelect-select {

            .MuiBox-root {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
            }
        }
    }
}

